.button {
	font-size: 16px !important;
	border-radius: 20px !important;
	padding: 5px 20px !important;
	font-weight: bold !important;
	color: #fff !important;
	border: 2px solid #ddd !important;
	background-color: transparent !important;

	&.active {
		color: #000 !important;
		border-color: #ffcd00 !important;
		background-color: #ffcd00 !important;
	}
}

.navitem{
	margin: 0;
	font: inherit;
	color: inherit;
	-webkit-text-decoration: none;
	text-decoration: none;
	color: inherit;
	font-family: Work Sans !important;
	font-weight: 600;
	color: #fff;
	margin-left: 32px;
	font-size: 16px;
	text-transform: uppercase;
	cursor: pointer;
}
